import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelp from "src/components/chromelp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"


const chromelpData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         height: 100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;

       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelp data={chromelpData}></Chromelp>
      </section>
    </HomepageLayout>
  )
}
